import axios from "axios";

/** 마켓 업로드  */
export const uploadMarketItem = () => {
  // 업로드 마켓은 어디인지
  // 업로드 상품 ID값은 무엇인지
  // 사용자 token으로 업로드 sqs 로 전달해야함 -> 서버 sqs consumer 에서 해당 ItemID와 userToken, UserUploadMarketId값을 전송
  // 서버에서는 userUploadMarket과 itemID값에서 정보 가져오기 . uploadMakret의 API값 가져와서 SQS consumer에서 업로드 처리
};
export const getMarketUploadItemList = () => {};
export const getMarketUploadDetail = () => {};
export const deleteMarketUploadItems = () => {};

/** 상품 수집 */
interface IScrapSite {
  id?: string;
  name: string;
  url: string;
}
export const getScrapSites = () => {
  /**
   아마존
알리
이베이
파페치
매치스페션
마이테레사

테무
타오바오
라쿠텐
11번가 슈팅/아마존
지마켓/옥션 스마일배송
네이버 
잘란도 
아이허브 
올리브영
   */
  const temp: IScrapSite[] = [
    { id: "1", name: "아마존", url: "" },
    { id: "2", name: "알리", url: "" },
    { id: "3", name: "이베이", url: "" },
    { id: "4", name: "파페치", url: "" },
    { id: "5", name: "매치스페션", url: "" },
    { id: "6", name: "마이테레사", url: "" },
    { id: "7", name: "테무", url: "" },
    { id: "8", name: "타오바오", url: "" },
    { id: "9", name: "라쿠텐", url: "" },
    { id: "10", name: "11번가 슈팅/아마존", url: "" },
    { id: "11", name: "지마켓/옥션 스마일배송", url: "" },
    { id: "12", name: "네이버", url: "" },
    { id: "13", name: "잘란도", url: "" },
    { id: "14", name: "아이허브", url: "" },
    { id: "15", name: "올리브영", url: "" },
  ];
  return temp;
};

export const requestScrapItemList = () => {
  return [];
};

export const requestScrapDetail = () => {
  return [];
};

export const addManualItem = () => {
  // 어느 소싱템플릿에 수동상품을 추가할건지
};

// 상품수집옵션으로 상품수집하기
export const requestScrapDetailWithOptions = () => {};

// 상품수집률 현황 정보
export const getScrapingProgress = () => {};

/** 상품 검수 */
export const passItem = () => {};
export const removeItem = () => {};

/**금지코드 관리 */
export const addBanCode = () => {};
export const updateBanCode = () => {};
export const deleteBanCode = () => {};

/** 메모  */
export const addMemo = () => {};
export const updateMemo = () => {};
export const deleteMemo = () => {};
// 단어 치환
// 태그 추가
