export const blue1="#577BB5"
export const blue2="#335A97"
export const blue3="#ECEFF2"
export const blue4="#26557D";
export const blue5="#03A2D5";
export const blue6="#61A0FF";
export const blue7="#7599EF";
export const blue8="#E6EEFA";
export const orange1="#FC6F21";
export const grey1="#E8E8E8";
export const grey2="#666666";
export const grey3="#F4F4F4";
export const grey4="#333333";
export const grey5="#999999";
export const grey6="#EEEBEBFF";
export const grey7="#D9D9D9";
export const red1="#CD5C5C";

