import * as React from "react";
import styled from "styled-components";
import { CustomRadio } from "../components/Btn/CustomRadio";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, CloseSharp } from "@mui/icons-material";
import { CustomCheck } from "../components/Btn/CustomCheck";
import AliSrc from "../images/company/ali.png";
import TaobaoSrc from "../images/company/taobao.png";
import AmazonSrc from "../images/company/amazon.png";
import * as XLSX from "xlsx";
import {
  ActivePageBtn,
  InactivePageBtn,
  PageBtns,
  PageBtnsWrapper,
} from "./AllGift";
import { ShowSelect } from "./MarketRegister";
import { grey2, grey6 } from "../const/colors";
import { ShowTableSelect } from "../components/Select/ShowTableSelect";
import { ExcelUploadDialog } from "../components/dialog/ExcelUploadDialog";
export interface IGiftCheckProps {}
interface RowData {
  sourcingSite: string;
  productName: string;
  thumbnail: string;
  originalPrice: number;
  discountedPrice: number;
  checked: boolean;
}

export function GiftCheck(props: IGiftCheckProps) {
  const [openUload, setOpenUpload] = React.useState(false);
  const [rowNum, setRowNum] = React.useState(10);
  const [rows, setRows] = React.useState<RowData[]>([
    { sourcingSite: "Amazon", productName: "Sample Item 1", thumbnail: "sample1.jpg", originalPrice: 21000, discountedPrice: 18000, checked: false },
    { sourcingSite: "AliExpress", productName: "Sample Item 2", thumbnail: "sample2.jpg", originalPrice: 15000, discountedPrice: 12000, checked: false },
    { sourcingSite: "Taobao", productName: "Sample Item 3", thumbnail: "sample3.jpg", originalPrice: 17000, discountedPrice: 14000, checked: false },
  ]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalPages = Math.ceil(rows.length / rowNum);
  const isAllSelected = rows.every(row => row.checked);
  const downloadExcelFile = () => {
    const worksheetData = rows.map(row => ({
      "소싱사이트": row.sourcingSite,
      "상품명": row.productName,
      "썸네일": row.thumbnail,
      "원가": row.originalPrice,
      "할인가": row.discountedPrice,
    }));
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SourcingProducts");
    XLSX.writeFile(workbook, "SourcingProducts.xlsx");
  };
  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const onOpenUpload = () => {
    setOpenUpload(true);
  };
  const onCloseUpload = () => {
    setOpenUpload(false);
  };
  const onChangeRowNum = (v: number) => {
    setRowNum(v);
  };

  const onCheckChange = (index: number) => {
    setRows(prevRows => 
      prevRows.map((row, i) =>
        i === index ? { ...row, checked: !row.checked } : row
      )
    );
  };
  const onSelectAllChange = () => {
    const newCheckedState = !isAllSelected;
    setRows(prevRows =>
      prevRows.map(row => ({
        ...row,
        checked: newCheckedState
      }))
    );
  };
  const onDeleteRow = (index: number) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      setRows(prevRows => prevRows.filter((_, i) => i !== index));
    }
  };
  const currentRows = rows.slice((currentPage - 1) * rowNum, currentPage * rowNum);
  return (
    <GiftCheckLayout>
      {/* 엑셀파일 업로드 창 */}
      {/* <Dialog open={openUload}>
        <DialogContent
          sx={{
            padding: "32px",
          }}
        >
          <DialogTitle
            sx={{
              color: "black",
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              sx={{
                opacity: 0,
                cursor: "default",
              }}
            >
              <CloseSharp
                sx={{
                  width: "20px",
                }}
              />
            </IconButton>
            <span>엑셀파일 업로드</span>
            <IconButton onClick={onCloseUpload}>
              <CloseSharp
                sx={{
                  width: "20px",
                }}
              />
            </IconButton>
          </DialogTitle>
          <UploadFileRow>
            <FileNameInput placeholder="파일명" />
            <FileBtns>
              <FileSelBtn>파일 선택</FileSelBtn>
              <FileSampleBtn>양식 파일 다운</FileSampleBtn>
            </FileBtns>
          </UploadFileRow>
          <FileUploadBtn>파일 업로드</FileUploadBtn>{" "}
        </DialogContent>
      </Dialog> */}
      <ExcelUploadDialog
          openUload={openUload}
          onCloseUpload={onCloseUpload}
        />
      <SearchRow>
        <SearchTxt>수집URL</SearchTxt>
        <SearchInput placeholder="수집명 미입력 시 “소싱사이트+ URL 내 검색단어”로 자동입력 됩니다." />
        <RadioSet>
          <span>URL입력</span>
          <CustomRadio initialChecked={true} />
          {/* <span>검색어입력</span>
          <CustomRadio /> */}
        </RadioSet>
      </SearchRow>

      <OptionRow>
        <Flex />
        <ShowTableSelect onChange={onChangeRowNum} />
      </OptionRow>

      <TableWrapper>
        <TableContainer variant="elevation" component={Paper} sx={{}}>
          <Table
            size="small"
            sx={{
              minWidth: 1200,
            }}
            aria-label="simple table"
          >
            <StyledTableHead>
              <TableRow>
                <StyledTableCell align="left" ><CustomCheck checked={isAllSelected} onChange={onSelectAllChange} /></StyledTableCell>
                <StyledTableCell align="left" sx={{ minWidth: 11 }}>No.</StyledTableCell>
                <StyledTableCell align="center">소싱사이트</StyledTableCell>
                {/* <StyledTableCell align="center">태그</StyledTableCell>
              <StyledTableCell align="center">글자수</StyledTableCell> */}
                <StyledTableCell align="center">상품명</StyledTableCell>
                <StyledTableCell align="center">썸네일</StyledTableCell>
                {/* <StyledTableCell align="center">카테고리</StyledTableCell> */}
                <StyledTableCell align="center">원가</StyledTableCell>
                {/* <StyledTableCell align="center">관세</StyledTableCell> */}
                <StyledTableCell align="center">할인가</StyledTableCell>
                {/* <StyledTableCell align="center">중간마진</StyledTableCell>
              <StyledTableCell align="center">배송비</StyledTableCell>
              <StyledTableCell align="center">마켓수수료</StyledTableCell>
              <StyledTableCell align="center">최종마진</StyledTableCell> */}
                <StyledTableCell align="center">삭제</StyledTableCell>
              </TableRow>
            </StyledTableHead>
            {/* 1.데이터가 있는경우 */}
            <TableBody>
              {currentRows
                .map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell align="left" scope="row">
                    <CustomCheck checked={row.checked} onChange={() => onCheckChange(index)} />
                    </TableCell>
                    <TableCell align="left" >{index + 1}</TableCell>
                    <TableCell align="center">
                      <img src={AmazonSrc} />
                      
                    </TableCell>
                    <TableCell align="center">MT-001</TableCell>
                    <TableCell align="center">썸네일</TableCell>
                    <TableCell align="center">21,000</TableCell>
                    <TableCell align="center">18,000</TableCell>

                    <TableCell align="center">
                    <DelBtn onClick={() => onDeleteRow(index + (currentPage - 1) * rowNum)}>삭제하기</DelBtn>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {/* 2.데이터가 없는 경우 */}
          {/* <NoDataPaper>
<span>수집중입니다.</span>
<span>수집률: 0%</span>
</NoDataPaper>  */}
        </TableContainer>
      </TableWrapper>
      <PageBtnsWrapper>
        <PageBtns>
          <ArrowBackIos sx={{ width: "16px" }} onClick={handlePrevPage} />
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
            page === currentPage ? (
              <ActivePageBtn key={page} onClick={() => onPageChange(page)}>{page}</ActivePageBtn>
            ) : (
              <InactivePageBtn key={page} onClick={() => onPageChange(page)}>{page}</InactivePageBtn>
            )
          ))}
          <ArrowForwardIos sx={{ width: "16px" }} onClick={handleNextPage} />
        </PageBtns>
      </PageBtnsWrapper>
      {/* <div
      style={{
        height:"100%"
      }}
      /> */}
      {/* 전체선택  */}
      <AllRow>
        <CustomRadio />
        <span>전체선택(4/11)</span>
        <DeleteBtn>전체삭제</DeleteBtn>
      </AllRow>
      <CheckBtns>
        <AllChkBtn>상품 수집</AllChkBtn>
        <SelectChkBtn>선택 상품 수집</SelectChkBtn>
        <BanCodeBtn>선택 일괄 금지코드 설정</BanCodeBtn>
        <Flex />
        <UploadBtn onClick={onOpenUpload}>엑셀파일 업로드</UploadBtn>
        <DownloadBtn onClick={downloadExcelFile}>엑셀파일 다운로드</DownloadBtn>
      </CheckBtns>
    </GiftCheckLayout>
  );
}
export const OptionRow = styled.div`
  margin-left: 62px;
  margin-right: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const DelBtn = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${grey6};
  color: ${grey2};
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
`;
const CompanyUl = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
`;
const FileUploadBtn = styled.button`
  border: none;
  color: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  height: 40px;
  background-color: #37508b;
  width: 100%;
  margin-top: 32px;
`;
const FileBtns = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
`;
const FileSampleBtn = styled.button`
  border: none;
  color: white;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  background-color: #7599ef;
  // width: 98px;
`;
const FileSelBtn = styled.button`
  border: 1px solid #37508b;
  color: #37508b;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  background-color: white;
  // width: 98px;
`;
const FileNameInput = styled.input`
  text-indent: 14px;
  width: 194px;
  height: 40px;
  background-color: #f4f4f4;
  border-radius: 5px;
  border: none;
`;
const UploadFileRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
`;
const DownloadBtn = styled.button`
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  color: white;
  border: 1px solid #1f7145;
  background-color: #1f7145;
  cursor: pointer;
`;
const UploadBtn = styled.button`
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  color: #1f7145;
  border: 1px solid #1f7145;
  background-color: white;
  cursor: pointer;
`;
const Flex = styled.div`
  flex: 1;
`;
const BanCodeBtn = styled.button`
  // width: 82px;
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  color: white;
  border: 1px solid #335a97;
  background-color: #335a97;
  cursor: pointer;
`;
const SelectChkBtn = styled.button`
  // width: 82px;
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  color: #335a97;
  border: 1px solid #335a97;
  background-color: #e6eefa;
  cursor: pointer;
`;
const AllChkBtn = styled.button`
  width: 75px;
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  color: #335a97;
  border: 1px solid #335a97;
  background-color: white;
  cursor: pointer;
`;
const CheckBtns = styled.div`
  width: calc(100% - 60px - 60px);
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 22px;
`;
const DeleteBtn = styled.button`
  width: 75px;
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  color: #666666;
  border: 1px solid #666666;
  background-color: white;
`;
const AllRow = styled.div`
  width: calc(100% - 60px - 60px);
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  font-size: 14px;
  color: #666666;
`;
const NoDataPaper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // position: absolute;
  // top:50%;
  // left: 60%;
  height: 90%;
  width: 100%;
  font-size: 16px;
  color: #757575;
`;
const TableWrapper = styled.div`
  width: calc(100% - 60px - 60px);
  margin-left: 60px;
  margin-right: 60px;
`;

const GiftCheckLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  min-width: 1400px;
  background-color: white;
`;

const SearchRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 45px;
  margin-bottom: 10px;
`;

const SearchTxt = styled.div`
  font-size: 18px;
  margin-left: 156px;
  margin-right: 67px;
`;

const SearchInput = styled.input`
  width: 780px;
  height: 37px;
  border: 1px solid #999999;
  border-radius: 5px;
  text-indent: 15px;
`;

const RadioSet = styled.div`
  margin-left: 57px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  font-size: 13px;
`;

const StyledTableHead = styled(TableHead)`
  background-color: #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  // box-shadow: none; /* 그림자 제거 */
`;

const StyledTableCell = styled(TableCell)`
  // border-top: 1px solid #6a6a6a;
  font-weight: bold !important;
`;

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number
) {
  return { name, calories, fat, carbs };
}

const rows2 = [
  createData("데이터1", 159, 6.0, 24),
  createData("데이터2", 237, 9.0, 37),
  createData("데이터3", 262, 16.0, 24),
  createData("데이터4", 305, 3.7, 67),
  createData("데이터5", 356, 16.0, 49),
];
