import { CloseSharp } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { blue7 } from '../../const/colors';

export interface IExcelUploadDialogProps {
    openUload: boolean;
    onCloseUpload: () => void;
}

export function ExcelUploadDialog({ openUload, onCloseUpload }: IExcelUploadDialogProps) {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [base64Data, setBase64Data] = React.useState<string | null>(null);
  const [fileNameOrLink, setFileNameOrLink] = React.useState<string>("");

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setFileNameOrLink(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          const base64String = reader.result.toString();
          setBase64Data(base64String);
          console.log("Base64 인코딩된 데이터:", base64String);
        }
      };
      reader.onerror = (error) => {
        console.error("파일 읽기 에러:", error);
      };
    }
  };

  const handleFileUpload = () => {
    if (selectedFile && base64Data) {
      console.log("업로드할 파일:", selectedFile);
      console.log("Base64 데이터:", base64Data);
      // 여기에 파일 업로드 로직을 추가합니다.
    } else {
      alert("업로드할 파일을 선택하세요.");
    }
  };

  const handleDownloadTemplate = () => {
    if (fileNameOrLink && !selectedFile) {
      const link = document.createElement('a');
      link.href = fileNameOrLink;
      link.setAttribute('download', 'template.xlsx'); 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("다운로드할 링크 주소를 입력하세요.");
    }
  };

  return (
    <Dialog open={openUload}
      sx={{
        "& .MuiDialog-paper": {
          overflow: "hidden",
          width: "460px",
          maxWidth: "80vw",
        }
      }}
    >
      <DialogContent
        sx={{
          padding: "32px",
          overflow: "hidden"
        }}
      >
        <DialogTitle sx={{
          color: "black",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}>
          <IconButton
            sx={{
              opacity: 0,
              cursor: "default"
            }}
          >
            <CloseSharp sx={{ width: "20px" }} />
          </IconButton>
          <span>엑셀파일 업로드</span>
          <IconButton onClick={onCloseUpload}>
            <CloseSharp sx={{ width: "20px" }} />
          </IconButton>
        </DialogTitle>
        <UploadFileRow>
          <FileNameInput 
            placeholder='링크 주소 또는 파일명'
            value={fileNameOrLink}
            onChange={(e) => {
              setFileNameOrLink(e.target.value);
              setSelectedFile(null); 
            }}
          />
          <FileBtns>
            <FileSelBtn>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileSelect}
                style={{
                  position: 'absolute',
                  opacity: 0,
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                }}
              />
              파일 선택
            </FileSelBtn>
            <FileSampleBtn onClick={handleDownloadTemplate}>
              양식 파일 다운
            </FileSampleBtn>
          </FileBtns>
        </UploadFileRow>
        <FileUploadBtn onClick={handleFileUpload}>
          파일 업로드
        </FileUploadBtn>
      </DialogContent>
    </Dialog>
  );
}

const FileUploadBtn = styled.button`
  border: none;
  color: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  height: 40px;
  background-color: #37508B;
  width: 100%;
  margin-top: 32px;
`;

const FileSelBtn = styled.button`
  border: 1px solid #37508B;
  color: #37508B;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  background-color: white;
`;

const UploadFileRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
`;

const FileNameInput = styled.input`
  text-indent: 14px;
  width: 194px;
  height: 40px;
  background-color: #F4F4F4;
  border-radius: 5px;
  border: none;
`;

const FileBtns = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
`;

const FileSampleBtn = styled.button`
  cursor: pointer;
  border: none;
  color: white;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  background-color: ${blue7};
`;
