import * as React from "react";

interface CheckProps {
  checked: boolean;
  onChange?: () => void;
}

export const CustomCheck: React.FC<CheckProps> = ({ checked, onChange }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onChange} // 클릭 이벤트 추가
      style={{ cursor: 'pointer' }} // 커서 포인터로 변경 (클릭 가능하게)
    >
      <rect
        x="1"
        y="0.5"
        width="15"
        height="15"
        rx="7.5"
        fill={checked ? "#335A97" : "#FDFDFD"}
        stroke={checked ? "#335A97" : "#C2C2C2"}
      />
      {checked && <path d="M4.5 8.2L7.16667 11L12.5 5" stroke="white" />}
    </svg>
  );
};
